export const environment =
{
  production: false,
  auth0: {
    domain: 'https://cloudgate.amx-digital.com',
    clientId: 'MbEVouqeNlxcx3WITuIGlx4pzoflP6yU',
    authorizationParams: {
      audience: 'https://portal-pab-prd',
      redirect_uri: 'https://productoabordo.aeromexico.com/callback',
    },
    errorPath: '/callback',
  },
  apiUrl: 'https://productoabordo.aeromexico.com/api',
  version : 'V2.1.1'
};
