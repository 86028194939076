import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { MasterService } from 'src/app/Services/master.service';
import { MaterialsService } from 'src/app/Services/materials.service';
import { CatMasterComponent } from '../../cat-master/cat-master.component';
import { TsuService } from 'src/app/Services/tsu.service'
import { LoginService } from 'src/app/Services/Login.service';
import { LanguageService } from 'src/app/Services/language.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  isInitial = false;
  ngAfterViewChecked(): void {
    if(this.hijo && !this.isInitial) {
      setTimeout(() => {
        this.dataMaster = this.hijo;
        this.isInitial = true;
      },1000 );
    }

  }
  //@share
  @Input() hijo;

  @BlockUI() blockUI: NgBlockUI;
  // Variables permisos --------------
  public canCreate;
  public canRead;
  public canUpdate;
  public canDelete;
  //----------------------------------

  public idUser: number;




  //Variables Idioma
  public varName : string
  public varObservations : string
  public varLanguage: string
  public varSessionExpired: string
  public varSuccessful: string
  public varWarning: string
  public varKitCreated: string
  public varTryAgain: string
  public varConnectionError: string
  public varLoading: string
  public varLocalStorage: string
  public varStatus: string
  public varAdd: string
  public varEdit: string
  public varDelete: string
  public varYes: string
  public varClose: string
  public varGlobalFilter: string
  public varSave: string
  public varClass: string
  public varCommentary : string
  public varTitleMaster: string
  public varMaterials: string
  public availableMaterials: Material[];
  public selectedMaterials: Material[];
  public draggedMaterial: Material;
  public filteredBrands: any[];
  public dataset: any[]
  public image: any
  public varQuantity: string
  public varPartNumber: string
  public varShortDescription: string
  public varDescriptionEnglish: string
  public filterArray: any[];
  public selectedCar1: ArrayTable[] = [];
  public materialCard: ArrayTable[] = [];
  public valueId;
  public arrayTable: any[]
  public quantity: number = 1
  public observation : string = ''
  public ocultar: boolean = true;
  public cols: any[];
  public kitTable: any;
  public arrayPartNumberTable: any[]
  public arrayDataDrop
  public varPhoto : string

  public varPicture: string
  public dataMaster
  public varAddKit: string

  public contId: any
  public contIdEdit:any
  public varUploadFile:any
  public expanded:any


  public MaterialsInfo : any []
  public AddMaterial :  any []

  public nameQuery : string
  public classeQuery : string
  public idUserQuery : string
  public path64Query : string
  public path64SchemeQuery : string
  public commentaryQuery : string

    // Varibales lenguaje
    public language;
    public languageGlobal;
    public languageModule;
    materialsSave: any;
    public flagArrayTable : string
    public commentary : string

  constructor(private toastr: ToastrService,
    private router: Router, private languagueService: LanguageService,
    private permissionsService: PermissionsService, private MasterService: MasterService,
    private materialService: MaterialsService, private CatMasterComponent : CatMasterComponent,
    private tsuService : TsuService,
    private route: ActivatedRoute,
    private loginService: LoginService) {
      this.language = languagueService.getLanguage();
      this.languageGlobal = this.language.global;
      this.languageModule = this.language.modules.tsu;

      this.subscription.add(this.languagueService.changeLanguage.subscribe(
        isEnglish => {
          this.language = languagueService.getLanguage();
          this.languageGlobal = this.language.global;
          this.languageModule = this.language.modules.tsu;
          this.getVariables();
          this.generateColumns();

        }
      ));
    this.canCreate = permissionsService.CanSubMenu(2, 12, 'C');
    this.canRead = permissionsService.CanSubMenu(2, 12, 'R');
    this.canUpdate = permissionsService.CanSubMenu(2, 12, 'U');
    this.canDelete = permissionsService.CanSubMenu(2, 12, 'D');

    this.subscription.add(this.route.queryParams.subscribe( data => {

      this.nameQuery = data.name
      this.classeQuery = data.classe
      this.commentaryQuery = data.commentary
      this.idUserQuery = data.idUser
      this.path64Query = data.path64
      this.path64SchemeQuery = data.path64Scheme
    }));
  }

  generateColumns() {
    this.cols = [
      { field: 'id', header: 'No.' },
      { field: 'name', header: this.varName },
      { field: 'descriptionEnglish', header: this.varDescriptionEnglish },
      { field: 'quantity', header: this.quantity },
      { field: 'partNumber', header: this.varPartNumber },
      { field: 'observation', header: this.varObservations }
    ];
  }
  ngOnInit() {
    this.varLocalStorage = localStorage.getItem('language')
    this.valueLanguge();
    //let personal = JSON.parse(localStorage.getItem("personal"));
    let personal = this.loginService.getUser();
    this.idUser = parseInt(personal.idUser.toString())
    this.getVariables()
    this.getDataMaterials();
    this.selectedMaterials = [];

  }
  sendCompleteKit(tableMaterial){

    this.MaterialsInfo = []
    let send ={
      name: this.nameQuery,
      clase: this.classeQuery,
      commentary: this.commentaryQuery,
      path: this.path64Query,
      path64Scheme: this.path64SchemeQuery,
      idUser: this.idUser,
      status: 1,
      materials: tableMaterial
    }
   if(send.materials !== undefined){
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.tsuService.add(send).subscribe((data: any) => {
      this.blockUI.stop();
      this.router.navigate(['/catTsu']);
      /*this.router.navigate(['/catTsu'], { queryParams: {message: this.nameQuery, type: 'add'}}).then(() => {
        window.location.reload();
      });*/
    }, error => {
      this.blockUI.stop();
      if (error.status === 401 || error.status === 0) {
        this.blockUI.stop();
        this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
        localStorage.setItem('logued', 'false');
        this.router.navigate(['/login']);
      } else if (error.status === 404) {
        this.blockUI.stop();
        this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      } else if (error.status === 500 || error.status === 400) {
        this.blockUI.stop();
        this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
      }
    }));
   }else{
    this.toastr.warning("Debes de seleccionar al menos un material.", 'Falta Material', { positionClass: 'toast-bottom-full-width', closeButton: true });
   }
  }

  valueLanguge() {
    if (localStorage.getItem('language') === 'true') {
      this.varLanguage = 'Ingles'
    } else if (localStorage.getItem('language') === 'false') {
      this.varLanguage = 'Español'
    }
  }

  getVariables() {
    this.varSessionExpired = this.languageGlobal.sessionExpired;
    this.varSuccessful = this.languageGlobal.successful;
    this.varWarning = this.languageGlobal.warning;
    this.varKitCreated = this.languageModule.kitCreated;
    this.varTryAgain = this.languageGlobal.tryAgain;
    this.varConnectionError = this.languageGlobal.connectionError;
    this.varLoading = this.languageGlobal.loading;
    this.varStatus = this.languageGlobal.status;
    this.varAdd = this.languageGlobal.add;
    this.varDelete = this.languageGlobal.delete;
    this.varEdit = this.languageGlobal.edit;
    this.varYes = this.languageGlobal.yes;
    this.varClose = this.languageGlobal.close;
    this.varGlobalFilter = this.languageGlobal.globalFilter;
    this.varSave = this.languageGlobal.save;
    this.varQuantity = this.languageModule.quantity;
    this.varPartNumber = this.languageModule.partNumber;
    this.varShortDescription = this.languageGlobal.shortDescription;
    this.varDescriptionEnglish = this.languageGlobal.descriptionEnglish;
    this.varMaterials = this.languageModule.materials;
    this.varName = this.languageGlobal.name;
    this.varObservations = this.languageModule.observations;
    this.varPicture = this.languageGlobal.picture;
    this.varAddKit = this.languageModule.addKit;
    this.varClass = this.languageGlobal.class;
    this.varPhoto = this.languageModule.photo;
    this.varCommentary = this.languageModule.commentary;
  }



  getDataMaterials() {
    this.blockUI.start(this.varLoading);
    this.subscription.add(this.materialService.getDataall().
      subscribe(
        (data: any) => {
          if (data.ClassInfo.Status === 200) {
            this.blockUI.stop();
            this.image = []
            let materialsLength = data.materials.length
            for (let i = 0; i < materialsLength; i++) {
              let imagen = data.materials[i].imege;
              this.image.push(data.materials[i].imege)
              this.availableMaterials = data.materials
              this.materialsSave = data.materials

            }



          }
        },
        error => {
          this.blockUI.stop();
          if (error.status === 401 || error.status === 0) {
            this.blockUI.stop();
            this.toastr.error(this.varSessionExpired, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
            localStorage.setItem('logued', 'false');
            this.router.navigate(['/login']);
          } else if (error.status === 404) {
            this.blockUI.stop();
            this.toastr.error(this.varTryAgain, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          } else if (error.status === 500 || error.status === 400) {
            this.blockUI.stop();
            this.toastr.error(this.varConnectionError, 'Error:', { positionClass: 'toast-bottom-full-width', closeButton: true });
          }
        }
      ));
  }


  addQuantity(valueQuantity : any, idQuantity: any){
    this.quantity = valueQuantity
  }



  drop(algo : any) {
    var cont = 0;
    if(this.flagArrayTable === 'true'){
      let arrayTable : any
      arrayTable = this.arrayTable
      this.arrayTable = []
      let arrayTableLength = arrayTable.length
    for (let i = 0; i < arrayTableLength; i++) {
      cont++;

      this.arrayTable.push({
        id: cont,
        quantity: arrayTable[i].quantity,
        partNumber: arrayTable[i].partNumber,
        shortDescription: arrayTable[i].shortDescription,
        descriptionEnglish: arrayTable[i].descriptionEnglish,
        imege: arrayTable[i].imege,
        idCatMaterials: arrayTable[i].idCatMaterials,
        commentary: arrayTable[i].commentary,
        observation: arrayTable[i].observation,
        reusable: arrayTable[i].reusable,
      })
    }

    let arrayDrag = []
    arrayDrag.push(this.draggedMaterial)
    this.quantity = 1
    this.commentary = ''
    for (let j = 0; j < arrayDrag.length; j++) {
      cont++;

      this.arrayTable.push({
        id: cont,
        quantity: this.quantity,
        partNumber: arrayDrag[j].partNumber,
        shortDescription: arrayDrag[j].shortDescription,
        descriptionEnglish: arrayDrag[j].descriptionEnglish,
        imege: arrayDrag[j].imege,
        idCatMaterials: arrayDrag[j].idCatMaterials,
        commentary: this.commentary,
        reusable: false,
      })

    }
    let draggedMaterialIndex = this.findIndex(this.draggedMaterial);
    this.selectedMaterials = [...this.selectedMaterials, this.draggedMaterial];
    this.availableMaterials = this.availableMaterials.filter((val, i) => i != draggedMaterialIndex);
    this.AddMaterial = []
    for (let j = 0; j < this.arrayTable.length; j++) {
      this.AddMaterial.push({ idMaterials: this.arrayTable[j].idCatMaterials, quality: this.arrayTable[j].quantity, reusable: this.arrayTable[j].reusable, commentary: this.arrayTable[j].commentary  })
    }
    }else{
      this.arrayTable = []
      this.quantity = 1
    this.commentary = ''
    if (this.draggedMaterial) {
      let draggedMaterialIndex = this.findIndex(this.draggedMaterial);
      this.selectedMaterials = [...this.selectedMaterials, this.draggedMaterial];
      this.availableMaterials = this.availableMaterials.filter((val, i) => i != draggedMaterialIndex);
      let contador = this.selectedMaterials.length

      for (let i = 0; i < contador; i++) {
        cont++;

        this.arrayTable.push({
          id: cont,
          quantity: this.quantity,
          partNumber: this.selectedMaterials[i].partNumber,
          shortDescription: this.selectedMaterials[i].shortDescription,
          descriptionEnglish: this.selectedMaterials[i].descriptionEnglish,
          imege: this.selectedMaterials[i].imege,
          idCatMaterials: this.selectedMaterials[i].idCatMaterials,
          commentary: this.commentary,
          reusable: false,
        })

      }

    }
    this.AddMaterial = []
    let arrayTableLength = this.arrayTable.length
    for (let j = 0; j < arrayTableLength; j++) {
      this.AddMaterial.push({ idMaterials: this.arrayTable[j].idCatMaterials, quality: this.arrayTable[j].quantity, reusable: this.arrayTable[j].reusable, commentary: this.arrayTable[j].commentary  })
    }
    this.flagArrayTable = 'true'

    }

  }


  saveTable(event) {
  }


  dragEnd(algo : any) {

    this.draggedMaterial = null;
  }
  dragStart(event, material: Material) {
    this.draggedMaterial = material;

  }

  deleteMaterial() {
    if (this.selectedCar1.length > 0) {
      this.selectedCar1.forEach(element => {
        var index: number = this.arrayTable.indexOf(this.selectedCar1.find(x => x.id == element.id));
        this.arrayTable.splice(index, 1);


      });
      let selectedCar1Length = this.selectedCar1.length
      this.arrayPartNumberTable = []
      for (let i = 0; i < selectedCar1Length; i++) {
        this.arrayPartNumberTable.push(this.selectedCar1[i].partNumber)

      }
      let arrayTableLength = this.selectedMaterials.length
      let arrayPartNumberTableLength = this.arrayPartNumberTable.length
      for (let t = 0; t < arrayTableLength; t++) {
        let numPartMaterial = this.selectedMaterials[t].partNumber
        for (let l = 0; l < arrayPartNumberTableLength; l++) {
          let numPartTable = this.arrayPartNumberTable[l]
          if (numPartMaterial.trim() === numPartTable.trim()) {
            this.availableMaterials.push(this.selectedMaterials[t])
          }
        }
      }
    }
    this.selectedCar1 = []
  }
  findIndex(material: Material) {
    let index = -1;
    for (let i = 0; i < this.availableMaterials.length; i++) {
      if (material.partNumber === this.availableMaterials[i].partNumber) {
        index = i;
        break;
      }
    }
    return index;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  filterMaterials(input) {

    let materialsTemp = this.materialsSave.filter( m => m.partNumber.includes( input.toUpperCase()));
    this.availableMaterials = [];
    this.availableMaterials = materialsTemp;
  }
}

export interface Material {
  quantity?;
  partNumber?;
  shortDescription?;
  descriptionEnglish?;
  typeofSupply?;
  familydescription?;
  imege?;
  idCatMaterials?;

}

export interface ArrayTable {
  id?;
  shortDescription?;
  descriptionEnglish?;
  quantity?;
  partNumber?;
  observation?;
  idCatMaterials?;

}
